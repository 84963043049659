/* desktop xl xxl */

@media(max-width:2600px) {

    .nav {
        height: 120px;
        background-color: transparent;
        color: #fff !important;
    }

    a,
    p,
    h1,
    h2,
    h3,
    h4,
    .nav-link {
        color: #ffffffc3 !important;
    }

    .nav-style {
        backdrop-filter: blur(10px);
    }

    .navbar-toggler-icon {
        background-image: url("../../src/assets/logo-icon.png");
    }
}


/* desktop lg */
@media(max-width:992px) {
    .font-nav-mob {
        padding-left: 11px;
    }

    .active {
        border-bottom: none !important;
    }
}

/* desktop md */

.logo-respons {
    width: 250px;
    margin-left: 10px;
}

@media(max-width:768px) {
    .logo-respons {
        width: 200px;
    }

    .active {
        border-bottom: none !important;
    }
}

.navbar-toggler:focus {
    box-shadow: none;
}

.navbar-toggler {
    border-color: transparent;
}

.active {
    border-bottom: 0.75px solid #fff; 
  }