/* desktop xl xxl */

/* Services.css */
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate {
    animation: fadeIn 1s ease-out;
  }
  

@media(max-width:2600px) {

    .service-text {
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        max-width: 90%;
    }

    .service-text-dev {
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-left: 10%;
    }

    .service-title {
        font-size: 28px;
        font-style: normal;
        font-weight: 600 !important;
        line-height: normal;
    }

    .service-subtitle {
        margin-bottom: 3rem;
        font-size: 24px;
    }

    .service-icon-left {
        height: 83px;
        margin-right: 2rem;
    }

    .service-icon-rigth {
        height: 65px;
        margin-left: 2rem;
    }

    .service-left-style {
        border-radius: 0px 212px 212px 0px;
        border-top: 1px solid #A259FF;
        border-right: 3px solid #A259FF;
        border-bottom: 1px solid #A259FF;
        height: 180px;
    }

    .service-right-style {
        border-radius: 212px 0px 0px 212px;
        border-top: 1px solid #A259FF;
        border-left: 3px solid #A259FF;
        border-bottom: 1px solid #A259FF;
        height: 180px;
        text-align: end;
    }
}

.mb-services {
    margin-bottom: 5rem;
}

/* tablet */

@media(max-width:992px) {
    .service-title-section {
        margin-top: 3rem;
    }

    .service-left-style {
        height: 200px;
    }
}

/* mobile */

@media(max-width:768px) {
    .service-left-style {
        height: 300px;
        padding-right: 2rem;
    }

    .service-right-style {
        height: 300px;
        padding-left: 2rem;
    }

    .service-text {
        font-size: 16px;
    }

    .service-text-dev {
        font-size: 16px;
    }

    .mobile-figma,
    .mobile-desarrollo,
    .mobile-diseno {
        display: none !important;
    }

    .mb-services {
        margin-bottom: 5rem;
    }

    .service-subtitle {
        font-size: 18px;
    }
}