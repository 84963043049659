.form-style {
    background-color: transparent; 
    color: #fff !important;
    border: none !important;
    border-bottom: 0.5px solid #fff !important;
    border-radius: 0px;
    transition: background-color 0.3s ease; 

}

.form-style:focus {
    background-color: transparent;
    box-shadow: none;
}

.form-style::placeholder {
    color: #fff;
    opacity: 1; 
}

.error-message {
    color: rgb(27, 22, 22)!important;
    font-size: 0.9rem; 
    margin-top: 0.5rem;
    border: 2px;
    background-color: #ffffff59;
    width: 35%;
    padding-left: 0.5rem;
    margin-left: 0.25rem;
}

@media(max-width:768px) {
    .error-message {
        width: 100%;
    }
}