.float {
    position: fixed;
    width: 80px; 
    height: 80px; 
    bottom: 60px; 
    right: 60px; 
    z-index: 100;
}

.float img {
    width: 100%; 
    height: 100%; 
    border-radius: 50%; 
}
