@import url('https://fonts.googleapis.com/css2?family=Urbanist&display=swap');

body {
  background: rgb(2, 6, 49);
  background: linear-gradient(150deg, rgba(2, 6, 49, 1) 45%, rgba(177, 45, 253, 1) 100%);
  font-family: 'Urbanist', sans-serif;
}

p {
  font-family: Urbanist, sans-serif;
}

/* CSS para la clase que oculta los elementos inicialmente */
.hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

/* CSS para la clase que muestra los elementos */
.animate {
  opacity: 1;
  visibility: visible;
}
