@font-face {
    font-family: 'Hussar';
    src: url('../assets/font-hussar.otf') format('truetype');
    /* Puedes agregar más formatos de fuente aquí si es necesario */
}

.texto-portada-2 {
    font-family: 'Hussar';
    font-size: 50px;
}

/* desktop xl xxl */

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.row.animated {
    animation: fadeIn 1s ease-out;
}

@media(max-width:2600px) {

    .portada-back {
        height: 100vh;
        color: #fff;
        display: flex;
        align-items: center;
    }

    .text-resalt {
        background: rgb(26, 148, 219);
        border-radius: 10px;
        font-size: 1.5rem;
        text-align: center;
    }

    .texto-portada {
        font-size: 2rem;
    }

    .texto2-portada {
        font-size: 1.5rem;
        font-weight: 100;
    }

    .img-portada {
        margin-top: 15%;
        margin-left: 15%;
    }

    .width-img {
        max-width: 50%;
    }
}

/* desktop lg */

@media(max-width:1200px) {
    .img-portada {
        max-width: 90%;
        margin-top: 0px;
        margin-left: 33%;
        margin-bottom: 10%
    }

    .width-img {
        max-width: 48%;
    }
}

/* tablet */

@media(max-width:992px) {
    .img-portada {
        max-width: 80%;
        margin-top: 20%;
    }

    .texto-portada {
        font-size: 1.5rem;
    }

    .texto-portada-2 {
        font-size: 40px;
    }

    .width-img {
        max-width: 60%;
    }

    /* .portada-direction {
        flex-direction: column-reverse;
    } */
}

/* mobile */

@media(max-width:768px) {
    .img-portada {
        max-width: 85%;
        margin-top: 5rem;
        margin-left: 7%;
    }

    .texto-portada {
        font-size: 18px;
        align-items: center;
    }

    .texto-portada-2 {
        font-size: 37px;
    }

    .portada-cent {
        align-items: center;
        padding-left: 0px;
    }

    .width-img {
        max-width: 90%;
    }

    .img-portada {
        margin-left: 10%;
    }
}


/* min mobile */

@media(max-width:576px) {

    .portada-direction {
        flex-direction: column-reverse;
    }

    .texto-portada-2 {
        font-size: 26px;
    }

    .width-img {
        max-width: 75%;
    }

    .img-portada {
        margin-top: 15%;
        margin-left: 0;
    }
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}