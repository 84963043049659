.button-style {
    background-color: rgb(18, 1, 74);
    border-color: #A259FF;
    border-radius: 55px;
    height: 52px;
}

.button-style:hover {
    background-color: #A259FF;
    border-color: #A259FF;
}

.btn-primary {
    --bs-btn-active-bg: transparent !important;
    --bs-btn-active-border-color: #A259FF;
} 